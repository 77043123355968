import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

import Img1 from "../../images/Features1.svg"
import Img2 from "../../images/Features2.svg"
import Img3 from "../../images/Features3.svg"
import Img4 from "../../images/Features4.svg"
import Img5 from "../../images/Features5.svg"
import Img6 from "../../images/Features6.svg"
import Img7 from "../../images/Features7.svg"
import Img8 from "../../images/Features8.svg"
import { SectionDescription } from "components/misc/Typography";
import { motion } from "framer-motion";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row max-w-screen-xl mx-auto pt-20`;
const Column = tw.div`w-full mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
    tw`md:mt-0 md:ml-4 shadow-lg border rounded-xl`
]);

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20`}
`;

const TextContent = tw(motion.div)`flex flex-col md:flex-row max-w-screen-xl py-5 px-8`;

const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
// const Description = tw.p`text-start text-base font-medium leading-relaxed text-black mt-4 md:mr-4 break-words`

const BoxHeading = tw.h2`text-2xl font-black tracking-wide text-start pt-12`

// const Statistics = tw.div`mb-6 flex flex-wrap justify-center`

const Tab = styled.button`
  display: flex;
  font-size: 17px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  width: 100%;
  border: 2px solid transparent;
  justify-content: start;
  white-space: nowrap;
  font-weight: 500;
  ${({ active }) =>
        active &&
        `
    border: 2px solid transparent;
    border-radius: 10px;
    opacity: 1;
    background-color: #ededff;
  `}
`;
const ButtonGroup = styled.div`justify-center`;
const types = [
    { label: 'Order Management', imgUrl: Img1 },
    { label: 'Digital Measurement Slip', imgUrl: Img2 },
    { label: 'Customer Management', imgUrl: Img3 },
    { label: 'Dashboard', imgUrl: Img4 },
    { label: 'Reports', imgUrl: Img5 },
    { label: 'Digital Invoicing', imgUrl: Img6 },
    { label: 'Stage Management', imgUrl: Img7 },
    { label: 'SMS & Whatsapp Update', imgUrl: Img8 }];


export default ({ heading = "Amazing Features", subheading = "Features", description = "Say goodbye to old mundane measurements slips and its data management. Get smarter and feature rich platform that helps your manage your business, customers and daily routine effectively. " }) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
    //Change the statistics variable as you like, add or delete objects

    const [activeUrl, setActiveUrl] = useState(Img1);

    const [active, setActive] = useState(0);

    const setNewImage = (key, url) => {
        setActive(key)
        setActiveUrl(url)
    }

    const descriptions = [
        {
            title: 'Keep your orders organized',
            desription: 'Manage your day to day stitching orders instantly in a very organized way. Get rid of noting down orders and managing large excel sheets. With date & amount wise sorting, orders management is much easier.',
            images: activeUrl
        },
        {
            title: 'Get rid of hips of slip books',
            desription: 'Great tool to enter measurement details of your customers conveniently and generate digital PDF slips which can be sent to the assigned tailor as well as customer’s whatsapp on a single click. Save time & cost!',
            images: activeUrl
        },
        {
            title: 'Managing customers is now so easy',
            desription: 'Import or Add new customers swiftly with auto generated customer code that helps you locate their orders. Additionally, easy customer search functions along with import/export customer database.',
            images: activeUrl
        },
        {
            title: 'Insightful analytics at a glance',
            desription: 'The main dynamic dashboard gives you detailed analysis on various day to day scenarios like pending amounts, upcoming deliveries, transaction details based on monthly yearly and quarterly at a glance.',
            images: activeUrl
        },
        {
            title: 'Statistical analysis of business',
            desription: 'Maap helps you generate three types of reports based on 1.Customers to understand their orders placed. 2. for Workers to know jobs assigned and 3. Category type to know what is more in demand.',
            images: activeUrl
        },
        {
            title: 'Single click invoice generation',
            desription: 'Auto generated PDF invoice with your business QR code and UPI details under each order are sent to customers whatsapp with a single click & thus helps receive payment swiftly. Saves printing costs isn’t it!',
            images: activeUrl
        },
        {
            title: 'Know where the order is',
            desription: 'With every order entered, it shows at which stage the order is and thus helps to manage daily operations easily by taking follow-ups and updating your customers about the status of their orders.',
            images: activeUrl
        },
        {
            title: 'Auto updates your customers',
            desription: 'With SMS and Whatsapp integration, maap automatically sends text or whatsapp message to your customers when their orders are placed or ready for a trial thus reducing calling & administrative time.',
            images: activeUrl
        },

    ]

    const Description = tw(SectionDescription)`w-full md:pr-2 text-left`;

    return (
        <Container id="features">
            <ThreeColumnContainer>
                <Subheading>{subheading}</Subheading>
                <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
                <TwoColumn>
                    <ButtonGroup>
                        {types.map((type, key) => (
                            <Tab
                                key={key}
                                active={active === key}
                                onClick={() => setNewImage(key, type.imgUrl)}
                            >
                                {type.label}
                            </Tab>
                        ))}
                    </ButtonGroup>
                    <TextColumn>
                        <TextContent
                            key={active}
                            variants={{
                                open: { opacity: 1, },
                                collapsed: { opacity: 0 }
                            }}
                            initial="collapsed"
                            animate={"open"}
                            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                        >
                            <div>
                                <BoxHeading>{descriptions[active].title}</BoxHeading>
                                <Description>{descriptions[active].desription}</Description>
                            </div>
                            {/* <Statistics> */}
                            <img src={descriptions[active].images} alt="abc" style={{ maxWidth: '350px', background: '#ededff', borderRadius: '10px' }}></img>
                            {/* </Statistics> */}
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
            </ThreeColumnContainer>
        </Container>
    );
};
