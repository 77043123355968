import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// import LogoImage from "images/logo_blue.png";
import LogoImage from "../../images/check2logo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import { HashLink } from "react-router-hash-link";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-primary-700 pb-1 transition duration-300`;
const LinkHash = tw(HashLink)`border-b-2 border-transparent hocus:border-primary-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = styled.input`${tw`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`} 
::placeholder{
    ${tw`text-gray-500`}
}`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const DesText = tw.h5`mt-2 text-sm tracking-wider text-gray-800`;
const SpanText = tw.h5`mt-2 text-sm tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 mb-8 md:mb-4 md:mt-4 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-primary-300 text-white hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
    return (
        <Container>
            <Content>
                <SixColumns>
                    <Column>
                        <ColumnHeading>Main</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <LinkHash smooth to="/#about">About</LinkHash>
                            </LinkListItem>
                            <LinkListItem>
                                <LinkHash smooth to="/#features">Features</LinkHash>
                            </LinkListItem>
                            <LinkListItem>
                                <LinkHash smooth to="/#benefits">Benefits</LinkHash>
                            </LinkListItem>
                            <LinkListItem>
                                <LinkHash smooth to="/#pricing">Pricing</LinkHash>
                            </LinkListItem>
                            <LinkListItem>
                                <LinkHash smooth to="/#testimonials">Testimonials</LinkHash>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>Product</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="https://getmaap.com/app/">Log In</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="https://getmaap.com/app/">Sign Up</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/demo">Book a demo</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>Legal</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="https://getmaap.com/contact_us.html" target={'_blank'}>Support</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="https://www.getmaap.com/privacy_policy.html" target={'_blank'}>Privacy policy</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="https://getmaap.com/terms_of_services.html" target={'_blank'}>Terms of service</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="https://getmaap.com/cancel.html" target={'_blank'}>Cancellation</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <SubscribeNewsletterColumn>
                        <SubscribeNewsletterContainer>
                            {/* <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
                            <SubscribeText>
                                We deliver high quality blog posts written by professionals weekly. And we promise no spam.
                            </SubscribeText>
                            <SubscribeForm method="get" action="#">
                                <Input type="email" placeholder="Your Email Address" />
                                <SubscribeButton type="submit">Subscribe</SubscribeButton>
                            </SubscribeForm> */}
                            <LogoContainer>
                                <LogoImg src={LogoImage} />
                                {/* <LogoText>maap</LogoText> */}
                            </LogoContainer>
                            <DesText>Maap is India’s 1st cloud platform empowering tailors with order and measurement management.</DesText>
                            <ThreeColRow>
                                <SocialLinksContainer>
                                    <SocialLink target="_blank" href="https://www.facebook.com/whitelotuscorporation">
                                        <FacebookIcon />
                                    </SocialLink>
                                    <SocialLink target="_blank" href="https://twitter.com/whitelotuscorp">
                                        <TwitterIcon />
                                    </SocialLink>
                                    <SocialLink target="_blank" href="https://www.instagram.com/whitelotuscorp">
                                        <InstagramIcon />
                                    </SocialLink>
                                    <SocialLink target="_blank" href=" https://www.linkedin.com/company/2486639/admin/">
                                        <LinkedInIcon />
                                    </SocialLink>
                                </SocialLinksContainer>
                            </ThreeColRow>
                            <SpanText><b>Phone:</b> +91 6355 851 755</SpanText>
                            <SpanText><b>Email:</b> contact@getmaap.com</SpanText>
                            <SpanText><b>Website:</b> <Link href="https://getmaap.com/">www.getmaap.com</Link></SpanText>
                        </SubscribeNewsletterContainer>
                    </SubscribeNewsletterColumn>
                </SixColumns>
                <Divider />
                <ThreeColRow>
                    {/* <LogoContainer>
                        <LogoImg src={LogoImage} />
                        <LogoText>maap</LogoText>
                    </LogoContainer> */}
                    <CopywrightNotice>Maap is a product of <a href="https://www.whitelotuscorporation.com/" target={'_blank'}> Whitelotus Corporation Pvt. Ltd. </a></CopywrightNotice>
                    {/* <SocialLinksContainer>
                        <SocialLink target="_blank" href="https://www.facebook.com/whitelotuscorporation">
                            <FacebookIcon />
                        </SocialLink>
                        <SocialLink target="_blank" href="https://twitter.com/whitelotuscorp">
                            <TwitterIcon />
                        </SocialLink>
                        <SocialLink target="_blank" href="https://www.instagram.com/whitelotuscorp">
                            <InstagramIcon />
                        </SocialLink>
                        <SocialLink target="_blank" href=" https://www.linkedin.com/company/2486639/admin/">
                            <LinkedInIcon />
                        </SocialLink>
                    </SocialLinksContainer> */}
                    <CopywrightNotice>&copy; 2022 maap. All Rights Reserved.</CopywrightNotice>
                </ThreeColRow>
            </Content>
        </Container >
    );
};
