import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import Img1 from "../../images/order_crop.webp"
import Img2 from "../../images/customer_crop.webp"
import Img3 from "../../images/dashboard_crop.webp"


// import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center lg:max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
// `;

// const Card = styled.div`
//   ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
//   .imageContainer {
//     ${tw`border text-center rounded-full p-5 flex-shrink-0`}
//     img {
//       ${tw`w-6 h-6`}
//     }
//   }

//   .textContainer {
//     ${tw`sm:ml-4 mt-4 sm:mt-2`}
//   }

//   .title {
//     ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
//   }

//   .description {
//     ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
//   }
// `;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Tab = styled.button`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: #f6f9fc;
  border: 0;
  outline: 0;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  transition-delay: 0.2s;
  width: 100%;
  border: 2px solid transparent;
  display:flex;
  align-items:center;${tw`justify-start md:justify-center`}
  ${({ active }) =>
    active &&
    `
    opacity: 1;
  `}
`;

const TabButtonText = styled.span`
${({ active }) =>
    active &&
    `
    padding-bottom: 4px;
    border-bottom: 2px solid #000;
    opacity: 1;
  `}
`
const ButtonGroup = styled.div`${tw`flex flex-col md:flex-row`}`;
// const types = [{ label: 'Dashboard', imgUrl: Img1 }, { label: 'Customer', imgUrl: Img2 }, { label: 'Orders', imgUrl: Img3 }];

const DetailsSections = styled(motion.div)`${tw`mt-12 border-2 border-black rounded-xl`}`

export default ({
  tabs = [{
    label: 'Orders',
    imgUrl: Img1,
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" tw="w-6 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
    </svg>

  },
  {
    label: 'Customer',
    imgUrl: Img2,
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" tw="w-6 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
    </svg>

  },
  {
    label: 'Dashboard',
    imgUrl: Img3,
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" tw="w-6 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
    </svg>
  }],
  cards = null, heading = "", subheading = "", description = "", color }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security."
    },
    { imageSrc: SupportIconImage, title: "24/7 Support" },
    { imageSrc: CustomizeIconImage, title: "Customizable" },
    { imageSrc: ReliableIconImage, title: "Reliable" },
    { imageSrc: FastIconImage, title: "Fast" },
    { imageSrc: SimpleIconImage, title: "Easy" }
  ];

  if (!cards) cards = defaultCards;

  const [activeUrl, setActiveUrl] = useState(Img1);

  const [active, setActive] = useState(0);

  const setNewImage = (key, url) => {
    setActive(key)
    setActiveUrl(url)
  }

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <div style={{ background: '#f6f9fc', padding: "30px", width: '100%', borderRadius: '1rem' }} >
          <div style={{ width: '100%' }}>
            <ButtonGroup>
              {tabs.map((type, key) => (
                <Tab
                  key={key}
                  active={active === key}
                  onClick={() => setNewImage(key, type.imgUrl)}
                >
                  {type.icon}
                  <TabButtonText tw="text-xl" active={active === key}>
                    {type.label}
                  </TabButtonText>
                </Tab>
              ))}
            </ButtonGroup>
            <ButtonGroup>
              <DetailsSections
                key={activeUrl}
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.90, ease: [0.04, 0.62, 0.23, 0.98] }}
              ><motion.img
                tw="rounded-xl" src={activeUrl}></motion.img></DetailsSections>
            </ButtonGroup>
          </div>
        </div>
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
