import React from "react";
// import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, HashNavLink, PrimaryLink, SecondaryLink } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase, SecondaryButton as SecondaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";



const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-center items-center lg:pt-12 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-4xl text-center`;
const Heading = tw(SectionHeading)`text-center text-black leading-snug xl:text-5xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 text-center pb-8`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5 m-2`;
const DefaultButton = tw(SecondaryButtonBase)` mt-8 inline-block w-56 tracking-wide text-center py-[1.12rem] m-2`;


export default ({
  heading = "Maap is India’s 1st cloud platform empowering tailors with order and measurement management.",
  description = "If world of fashion changes, so should your tailoring process. Maap is cloud based software crafted to digitalize whole tailoring process to deliver exceptional customer experiences in an easy and efficient way by freeing you from extra load of administrative work.",
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Get Started",
  defaultButtonUrl = "https://google.com",
  defaultButtonText = "Book a Demo",
  buttonRounded = true,
  features = ["Available in 7 Locations", "Premium Internet Backbone", "99.99% Uptime SLA"],
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <HashNavLink to={"/#about"} smooth>About</HashNavLink>
      <HashNavLink to={"/#features"} smooth >Features</HashNavLink>
      <HashNavLink to={"/#benefits"} smooth >Benefits</HashNavLink>
      <HashNavLink to={"/#pricing"} smooth>Pricing</HashNavLink>
      <HashNavLink to={"/#testimonials"} smooth>Testimonials</HashNavLink>
      {/* <NavLink to={"#contact-us"} >Contact Us</NavLink> */}
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink href="https://getmaap.com/app" tw="">
        Login
      </NavLink>
      <PrimaryLink css={buttonRoundedCss} href="https://getmaap.com/app?signup=true" tw="lg:hidden xl:inline">
        Sign Up
      </PrimaryLink>
      {/* <SecondaryLink maryLink css={buttonRoundedCss} href="/demo" tw="lg:ml-2! lg:hidden xl:inline">
        Book a demo
      </SecondaryLink> */}
    </NavLinks>
  ];
  return (
    <>
      <Header links={navLinks} />
    </>
  );
};
