import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
// import { Container } from "components/misc/Layouts.js";
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

// const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

// const HeadingContainer = tw.div``;
// const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
// const Heading = tw(SectionHeading)``;
// const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`w-full flex justify-between items-start min-h-[380px]`;
const FaqsColumn = tw.div`w-full lg:mr-12 last:mr-0`;
const Faq = styled.div`${props => props.border ? css`${tw`first:pt-0 last:border-0 border-b border-gray-400 transition-colors duration-300 py-6`}` : css`${tw`first:pt-0 transition-colors duration-300 py-6`}`}`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-[1rem] sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-[15px] sm:text-[1rem] tracking-wide leading-normal font-normal mt-4 text-black`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  faqs = []
}) => {
  const faqCol = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)} border={true}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {typeof faq.answer != 'string' ? faq.answer.map(item => <li>{item}</li>) : faq.answer}
        </Answer>
      </Faq>
    );

    faqCol.push(renderedFaq);

    return null;
  });
  return (
    <FaqsContainer>
      <FaqsColumn>{faqCol}</FaqsColumn>
    </FaqsContainer>
  );
};
