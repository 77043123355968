import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8`;

// const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`mb-4 text-center w-32`;
const Heading = tw(
  SectionHeading
)`mt-4 mb-8 font-black text-3xl sm:text-4xl lg:text-5xl text-left leading-tight`;
// const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;
const Description = tw(SectionDescription)`w-full md:pr-2 text-center`;

const TestimonialsSlider = styled(Slider)`
  ${tw`flex mt-16 mx-auto text-left bg-gray-100 rounded-lg text-gray-900`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-8 focus:outline-none flex! flex-col justify-between h-full`
const QuoteContainer = tw.div`relative`
const QuoteIcon = tw(QuoteIconBase)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`
const CustomerInfoAndControlsContainer = tw.div`mt-8 flex items-center flex-col sm:flex-row justify-start text-center sm:text-left`
const CustomerImage = tw.img`w-16 h-16 rounded-full`
const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-0 flex flex-col justify-start`
const CustomerName = tw.span`text-lg font-semibold`
const CustomerProfile = tw.span`text-sm font-normal text-gray-700`
const ControlsContainer = tw.div`sm:ml-auto flex`
const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-primary-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;

export default ({
  subheading = "Story",
  heading = "Our maap story",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  testimonials = [
    {
      customerName: "- Team Maap",
      customerProfile: "- Team Maap",
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
      quote:
        "The title ‘maap’ is a Gujarati linguistic word meaning ‘measurement’. As a customer, we always hear from tailors at stitching stores saying ‘maap api do’, which translates to please allow us to take your measurement for stitching your clothing order. That’s how we decided upon ‘maap’." ,
      quote1: "Furthermore, as a customer whenever we go to tailors for stitching needs, we observed that their day to day operations are still not digitalized and involves lots of manual work. They are noting down customer’s measurements on a paper measurement slips (they print lots of slip books), they are maintaining daily orders either on an excel sheet or note books, their customer update process is tedious during work load festive and wedding periods as they have lots of daily orders to manage and execute. So data & process is there but unorganized, either in their memory, on papers or data sheets. It’s frustrating and hence a hindrance to business expansion for many tailors as they described us their problem because things are not yet digitalized. So we built Maap!",
      quote2: "As a solution to the industry’s current problems, to reduce the extra administrative work and manual process, we founded ‘maap’ and decided to create a finest cloud based platform that manages entire cycle of customer very smoothly by focusing on key aspects like customers’ orders, their measurements, updating customers through auto messaging and digital invoicing process in a single cloud application.",
      quote3: "We hope maap empowers our entire stitching tailor industry through the means of digitalization which helps them to focus more on operations & customer service and less on the administrative headache by turning out to be a very useful tool.",
      quote4: "As we launch ‘maap’ we aim to focus on solving further hurdles in their daily operations that can help tailoring industry to grow by enhancing our platform with futuristic promising features.",
    },
    {
      customerName: "Serena Davis",
      customerProfile: "Founder, Travana",
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
      quote:
        "We are delighted with the quality and performance of the servers that servana provides. The uptime is amazing and the internet connection is great for the price we are paying."
    },
    {
      customerName: "Timothy Burr",
      customerProfile: "CTO, Coronax",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "It has been 8 months since we have switched to servana and it has nothing but an amazing experience. The cost is affordable, support is great, uptime is as described."
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    <PrimaryBackgroundContainer id="about" >
      <ContentWithPaddingXl>
        {/* <Subheading>{subheading}</Subheading> */}
        {/* <Heading>{heading}</Heading> */}
        {/* {description && <Description>{description}</Description>} */}
        <TestimonialsSlider arrows={false} ref={setSliderRef}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <Heading>{heading}</Heading>
              <QuoteContainer>
                <QuoteIcon />
                <Quote>
                  {testimonial.quote}
                </Quote>
                <Quote>&nbsp;</Quote>
                <Quote>
                  {testimonial?.quote1}
                </Quote>
                <Quote>&nbsp;</Quote>
                <Quote>
                  {testimonial?.quote2}
                </Quote>
                <Quote>&nbsp;</Quote>
                <Quote>
                  {testimonial?.quote3}
                </Quote>
                <Quote>&nbsp;</Quote>
                <Quote>
                  {testimonial?.quote4}
                </Quote>
              </QuoteContainer>
              <CustomerInfoAndControlsContainer>
                {/* <CustomerImage src={testimonial.imageSrc} /> */}
                <CustomerNameAndProfileContainer>
                  <CustomerName>
                    {testimonial.customerName}
                  </CustomerName>
                  <CustomerProfile>
                    {/* {testimonial.customerProfile} */}
                  </CustomerProfile>
                </CustomerNameAndProfileContainer>
                {/* <ControlsContainer>
                  <ControlButton onClick={sliderRef?.slickPrev}>
                    <ArrowLeftIcon className="icon" />
                  </ControlButton>
                  <ControlButton>
                    <ArrowRightIcon className="icon" onClick={sliderRef?.slickNext} />
                  </ControlButton>
                </ControlsContainer> */}
              </CustomerInfoAndControlsContainer>
            </Testimonial>
          ))}
        </TestimonialsSlider>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
