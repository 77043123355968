import React, { useState } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import { motion } from "framer-motion";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div`w-full`;

const HighlightedText = tw.span`text-primary-500`

const FAQContent = tw.div`flex justify-center items-center`;

const FAQSContainer = tw.div`border rounded-lg p-4 md:p-8 max-w-4xl w-full`;
const FAQ = tw.div` first:mt-0 mt-8 border-0 px-4 py-4 lg:p-0 rounded-lg lg:rounded-none overflow-hidden`;

export default ({ faqs = [] }) => {

    const defaultFaqs = [
        {
            question: "Subscription and Pricing",
            answer: [
                {
                    question: "Can I get a trial period before purchasing the subscription plan?",
                    answer:
                        "Yes, We offer a 90-day trial period for our platform. When your trial expires, you can continue using the product by adding a debit/credit card inside the “Subscription” section. If you decide to discontinue or feel like it’s not made for you, after the first paid month/year, you can choose not to add a credit/debit card at the end of your 90-day standard trial, your subscription will be downgraded to the “Free” plan."
                },
                {
                    question: "Will my data be lost while downgrading the subscription plan?",
                    answer:
                        "No, while upgrading or downgrading the subscription plan on the Maap SaaS platform, the customer’s data will not be lost and will persist until the customer intentionally opt-ins to delete the account from the platform. "
                },
                {
                    question: "What happens if I cancel my subscription?",
                    answer:
                        "If you cancel your subscription, you can use the platform until the end of the current subscription period and after that, your subscription will be downgraded to the “Free” plan and you will not be able to use the paid features of your previous subscription plan."
                },
                {
                    question: "Is there a demo version I can try?",
                    answer:
                        "Yes, you can select the “Free” plan, or to try paid features you can set up your free trial for 90 days of any premium plan."
                },
                {
                    question: "Is it safe to use my credit/debit card on the Maap platform?",
                    answer:
                        "Yes, you can select the “Free” plan, or to try paid features you can set up your free trial for 90 days of any premium plan."
                }
            ]
        },
        {
            question: "Maap Platform",
            answer: [
                {
                    question: "Can I Track My Order Status?",
                    answer:
                        "Yes, you can track all orders from the “Orders” section in the platform with different types of statuses such as “Order received”, “Assigned to the worker”, “In production”, etc. assigned to a particular category (“Kurta”, “Blazer”, etc.) of the entire order."
                },
                {
                    question: "Will the customer’s measurements be shared anywhere outside the platform?",
                    answer:
                        "By default Maap platform only shares measurement details with the assigned worker, but it is configurable inside the platform to share the measurement slip with the customer also."
                },
                {
                    question: "Can I customize the Maap platform according to my business needs?",
                    answer:
                        "Yes, you can freely customize and add new categories with different styles, also you will be able to choose multiple languages between English and Gujarati."
                }
            ]
        },
        {
            question: "Features",
            answer: [
                {
                    question: "What are the basic advantages/services provided by the Maap platform?",
                    answer: [
                        "Calculated data of total earnings and order counts of each category shown in form of graphs on the dashboard with filters (“Monthly”, “Quarterly” and “Yearly”).",
                        "Frequent upcoming orders will be displayed on the dashboard for easy and faster accessibility.",
                        "Customer details with measurements are stored, for easy and fast accessibility.",
                        "Easy to find orders with particular filtering from the list of all orders.",
                        "Features included in paid plans are automatically sending messages on SMS and Whatsapp."
                    ]
                },
                {
                    question: "Can I edit measurements if they don't look correct?",
                    answer:
                        "Yes. Our platform allows you to edit measurements for each particular order and category-wise."
                },
                {
                    question: "How will my customer get benefits if we use the Maap platform?",
                    answer: [
                        "Customers are provided with an automated invoice in PDF format at the end of the ordering process on their registered Whatsapp phone number.",
                        "Customers will receive an automated SMS on their registered phone number when the order will be ready to be delivered.",
                        "Customers can directly pay the bill from the UPI QR code provided in the invoice PDF."
                    ]
                }
            ]
        }
    ];

    if (!faqs || faqs.length === 0) faqs = defaultFaqs;

    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

    return (
        <Container >
            <Content >
                <TwoColumn>
                    <Column id="faqs" >
                        <Tabs subheading="FAQs" setActiveQuestionIndex={setActiveQuestionIndex} heading={<> Any <HighlightedText> Questions ?</HighlightedText></>} color="pink" />
                        <FAQContent>
                            <FAQSContainer>
                                <FAQ
                                    key={activeQuestionIndex}
                                    className="group"
                                >
                                    <TwoColumnPrimaryBackground faqs={defaultFaqs[activeQuestionIndex].answer}></TwoColumnPrimaryBackground>
                                </FAQ>
                            </FAQSContainer>
                        </FAQContent>
                    </Column>
                </TwoColumn>
            </Content>
        </Container>
    );
};

const Tabs = ({
    subheading = "Pricing",
    heading = "Any Questions ?",
    description = "",
    planDurations = [
        {
            text: "Subscription",
            switcherText: "Subscription",
        },
        {
            text: "Platform",
            switcherText: "Platform",
        },
        {
            text: "Features",
            switcherText: "Features",
        }
    ],
    setActiveQuestionIndex
}) => {

    const HeaderContainer = tw.div`w-full flex flex-col items-center`;
    const Subheading = tw(SubheadingBase)`mb-4`;
    const Heading = tw(SectionHeading)`w-full`;
    const Description = tw(SectionDescription)`w-full text-center`;

    const PlanDurationSwitcher = tw.div`block w-full flex justify-between items-center max-w-2xl bg-white drop-shadow-[0_0_1.5rem_rgba(162, 115, 255,0.4)] rounded-full py-2 px-2 mt-8`;
    const SwitchButton = styled.button`
  ${tw`px-2 py-4 basis-[33.3334%] rounded-full focus:outline-none text-[12px] xs:text-sm sm:text-lg font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;


    const [activeDurationIndex, setActiveDurationIndex] = useState(0);

    return (
        <Container>
            <ContentWithPaddingXl>
                <HeaderContainer>
                    {subheading && <Subheading>{subheading}</Subheading>}
                    <Heading>{heading}</Heading>
                    {description && <Description>{description}</Description>}
                    <PlanDurationSwitcher>
                        {planDurations.map((planDuration, index) => (
                            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => { setActiveDurationIndex(index); setActiveQuestionIndex(index) }}>{planDuration.switcherText}</SwitchButton>
                        ))}
                    </PlanDurationSwitcher>
                </HeaderContainer>
            </ContentWithPaddingXl>
        </Container >
    );

}

const TwoColumnPrimaryBackground = ({ faqs = [] }) => {

    const FaqsContainer = tw.div`w-full flex justify-between items-start min-h-[380px]`;
    const FaqsColumn = tw.div`w-full lg:mr-12 last:mr-0`;
    const Faq = styled.div`${props => props.border ? css`${tw`first:pt-0 last:border-0 border-b border-gray-400 transition-colors duration-300 py-6`}` : css`${tw`first:pt-0 transition-colors duration-300 py-6`}`}`;
    const Question = tw.div`flex justify-between items-center`;
    const QuestionText = tw.div`text-[1rem] sm:text-lg font-semibold tracking-wide`;
    const QuestionToggleIcon = styled(motion.span)`
        ${tw`ml-2 transition duration-300`}
        svg {
            ${tw`w-6 h-6`}
        }
    `;
    const Answer = tw(motion.div)`hidden text-[15px] sm:text-[1rem] tracking-wide leading-normal font-normal mt-4 text-black`;
    const faqCol = [];
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

    const toggleQuestion = questionIndex => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    faqs.map((faq, index) => {
        const renderedFaq = (
            <Faq key={index} onClick={() => toggleQuestion(index)} border={true}>
                <Question>
                    <QuestionText>{faq.question}</QuestionText>
                    <QuestionToggleIcon
                        variants={{
                            collapsed: { rotate: 0 },
                            open: { rotate: -180 }
                        }}
                        initial="collapsed"
                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                        transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <ChevronDownIcon />
                    </QuestionToggleIcon>
                </Question>
                <Answer
                    variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    key={activeQuestionIndex}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    {typeof faq.answer != 'string' ? faq.answer.map(item => <li>{item}</li>) : faq.answer}
                </Answer>
            </Faq>
        );

        faqCol.push(renderedFaq);

        return null;
    });


    return (
        <FaqsContainer>
            <FaqsColumn>{faqCol}</FaqsColumn>
        </FaqsContainer>
    );

}