import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import axios from 'axios'
import Swal from "sweetalert2";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/logo_blue.png";


const Container = tw.div`relative`;



const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto pt-16`}
`;
const Subheading = tw(SubheadingBase)`mb-4 mt-32 mx-0`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`



const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`text-gray-100 relative flex drop-shadow-2xl px-2 lg:px-32`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
const HeaderContainer = tw.div`w-full flex flex-col items-start`;
const TwoColumn = tw.div`flex flex-col px-12 justify-between`;
const Column = tw.div`flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6 text-center`;
const Label = tw.label`absolute top-0 tracking-wide font-semibold text-sm text-center`;
const Input = tw.input`text-left`;
const TextArea = tw.textarea`h-24 sm:h-full resize-none text-left`;
const SubmitButton = tw.button`w-32 mt-6 py-3 mb-12 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl justify-items-center`;


export default ({ cards = null, heading = "Amazing Benefits", subheading = "Try Now", description = "" }) => {
    const defaultCards = [
        { imageSrc: SupportIconImage, title: "Paperless", description: "With digital measurement slips and invoices generated, it reduces manual paperwork and saves on your printing costs. " },
    ];

    if (!cards) cards = defaultCards;

    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required('Full name is required').test('alphabets', 'Full name must only contain alphabets', (value) => {
            return /^[A-Za-z ]+$/.test(value);
        }).matches(/[^\s*]/g, 'Full name cannot contain only blankspaces'),
        email: Yup.string().required('Email is required').email('Email must be a valid email'),
        phone_number: Yup.string().required('Phone number is required').matches(/^[0-9]+$/, 'Phone phone number contain only number').min(10, 'Phone phone number must be at least 10 characters'),
        message: Yup.string().required('Message is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, setValue, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    function onSubmit({ full_name, email, phone_number, message }) {
        const apiBookDemoRequest = {
            full_name: full_name,
            email: email,
            phone_number: phone_number,
            message: message
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post('https://api.getmaap.com/api/superadmin/create/demo', apiBookDemoRequest, { headers: headers }).then(res => {
            if (res.data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonColor: '#5E44FF',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        reset()
                    }
                })
            }
        })
    }

    return (
        <AnimationRevealPage>
            <Hero />
            <Container>
                <Content>
                    <FormContainer>
                        <div tw="lg:flex lg:flex-col hidden basis-[50%] bg-white rounded-l-lg text-left pl-12 pr-12">
                            <HeaderContainer>
                                {subheading && <Subheading>{subheading}</Subheading>}
                            </HeaderContainer>
                            <h2 tw="mt-8 text-sm text-black">Book a Demo</h2>

                            <p tw="text-base text-black text-xl mt-8">
                                Learn how maap transforms your tailor process digitally and helps you save unnecessary administrative time & cost.
                            </p>
                            <p tw="pb-4 mt-8">We need just 15mins to introduce you to maap. Fill out the form on your right or shoot us an email to <a href="mailto:contact@getmaap.com" tw="text-[#6415FF]">contact@getmaap.com</a></p>

                            <p tw="pb-32">Some of your important queries are already answered in our <a href="/#faqs" tw="text-[#6415FF]">FAQ</a> section. </p>
                        </div>
                        <div tw="basis-[100%] lg:basis-[50%] bg-gray-100 rounded-r-lg">
                            <ThreeColumnContainer>

                                {cards.map((card, i) => (
                                    <Column key={i}>
                                        <Card>
                                            <span className="imageContainer">
                                                <img src={card.imageSrc || defaultCardImage} alt="" />
                                            </span>

                                        </Card>
                                    </Column>
                                ))}
                            </ThreeColumnContainer>
                            <DecoratorBlob />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TwoColumn>
                                    <Column>
                                        <InputContainer>
                                            <Label htmlFor="name-input">Your Full Name</Label>
                                            <Input id="name-input" style={{ color: 'black' }} type="text" name="full_name" placeholder="E.g. John Doe" {...register('full_name')}/>
                                            <span tw={'flex justify-end font-normal text-sm text-red-500'} >{errors.full_name?.message}</span>
                                        </InputContainer>
                                        <InputContainer>
                                            <Label htmlFor="email-input">Your Email Address</Label>
                                            <Input id="email-input" style={{ color: 'black' }} type="email" name="email" placeholder="E.g. john@mail.com" {...register('email')}/>
                                            <span tw={'flex justify-end font-normal text-sm text-red-500'} >{errors.email?.message}</span>
                                        </InputContainer>
                                        <InputContainer>
                                            <Label htmlFor="number-input">Your Phone Number</Label>
                                            <Input id="number-input" style={{ color: 'black' }} type="text" name="number" placeholder="+91 9876543210" {...register('phone_number')}/>
                                            <span tw={'flex justify-end font-normal text-sm text-red-500'} >{errors.phone_number?.message}</span>
                                        </InputContainer>
                                        <InputContainer tw="flex-1">
                                            <Label htmlFor="message-input">Your Message</Label>
                                            <TextArea id="message-input" style={{ color: 'black' }} name="message" placeholder="E.g. Details about your event" {...register('message')}/>
                                            <span tw={'flex justify-end font-normal text-sm text-red-500'} >{errors.message?.message}</span>
                                        </InputContainer>
                                    </Column>
                                </TwoColumn>
                                <div tw="text-center" ><SubmitButton type="submit" value="Submit">Submit</SubmitButton></div>
                            </form>
                        </div>
                    </FormContainer>
                </Content>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );

};