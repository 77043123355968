import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/check2logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { HashLink } from "react-router-hash-link";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const HashNavLink = tw(HashLink)`
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
`;
export const SecondaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-transparent text-primary-500 border-2 border-primary-500
  hocus:bg-primary-100 hocus:border-primary-100 hocus:bg-opacity-25 focus:shadow-outline
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-48 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
      {/* maap */}
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};




// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import logo from "../../images/check2logo.png";
// import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
// import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

// class Header extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       showNavLinks: false,
//     };
//   }

//   toggleNavbar = () => {
//     this.setState((prevState) => ({
//       showNavLinks: !prevState.showNavLinks,
//     }));
//   };

//   render () {
//     const {
//       roundedHeaderButton = false,
//       logoLink,
//       links,
//       className,
//       collapseBreakpointClass = "lg",
//     } = this.props;

//     const { showNavLinks } = this.state;
//     const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];
//     const defaultLogoLink = (
//       <Link to="/" className="logo-link">
//         <img src={logo} alt="logo" />
//         {/* maap */}
//       </Link>
//     );
//     const renderedLogoLink = logoLink || defaultLogoLink;

//     return (
//       <header className={className || "header-light"}>
//         <nav className={`desktop-nav-links ${collapseBreakpointCss.desktopNavLinks}`}>
//           {renderedLogoLink}
//           {links}
//         </nav>

//         <nav className={`mobile-nav-links-container ${collapseBreakpointCss.mobileNavLinksContainer}`}>
//           {renderedLogoLink}
//           <div
//             className={`mobile-nav-links ${showNavLinks ? "open" : "closed"} ${collapseBreakpointCss.mobileNavLinks}`}
//             initial={{ x: "150%", display: "none" }}
//           >
//             {links}
//           </div>
//           <button onClick={this.toggleNavbar} className={`nav-toggle ${showNavLinks ? "open" : "closed"}`}>
//             {showNavLinks ? <CloseIcon className="w-6 h-6" /> : <MenuIcon className="w-6 h-6" />}
//           </button>
//         </nav>
//       </header>
//     );
//   }
// }

// export default Header;

// const collapseBreakPointCssMap = {
//   sm: {
//     mobileNavLinks: "hidden",
//     desktopNavLinks: "flex",
//     mobileNavLinksContainer: "hidden",
//   },
//   // ... other breakpoint mappings
// };
