import { createGlobalStyle } from 'styled-components'
import { globalStyles } from 'twin.macro'
import Gordita from '../fonts/gordita/Gordita-Regular.otf'

const GlobalStyles = createGlobalStyle(globalStyles, `
  @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700&display=swap');
  @font-face {
    font-family: 'gordita';
    src: url(${Gordita});
  }
  .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
   *, html{
    font-family: 'Urbanist', sans-serif;
    color: #000000;
   }
`)

export default GlobalStyles