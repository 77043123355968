import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
import axios from 'axios'

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
// const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300 cursor-pointer`}
`;
// const BuyNowButton = styled(PrimaryButtonBase)`
//   ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
// `;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
    subheading = "Pricing",
    heading = "Individual or Enterprise? We’ve got you covered.",
    description = "",
    plans = null,
    primaryButtonText = "Try it Free",
    planDurations = [
        {
            text: "Month",
            switcherText: "Monthly",
        },
        {
            text: "Year",
            switcherText: "Yearly",
        }
    ]
}) => {
    const defaultPlans = [
        {
            name: "Free",
            durationPrices: ["₹0", "₹0"],
            mPrice: ["₹0", ".00/month"],
            yPrice: ["₹0", ".00/month"],
            oldPrice: "",
            description: "Perfect to learn and understand how maap is helpful to streamline your tailor business.",
            features: ["Unlimited customers", "Upto 100 orders"],
            url: "https://google.com",
            m_link: "http://localhost:3000?signup=true&billing_cycle=month&plan=Free",
            y_link: "http://localhost:3000?signup=true&billing_cycle=year&plan=Free",
            featured: ""
        },
        {
            name: "Individual",
            mPrice: ["₹450", ".00/month"],
            yPrice: ["₹400", ".00/month"],
            oldPrice: "",
            description: "Perfect for single location stores to manage their daily stitching orders and deliveries.",
            features: ["Unlimited customers", "Unlimited orders", "Generate reports"],
            url: "https://google.com",
            featured: "",
            m_link: "http://localhost:3000?signup=true&billing_cycle=month&plan=Individual",
            y_link: "http://localhost:3000?signup=true&billing_cycle=year&plan=Individual",
        },
        {
            name: "Enterprise",
            mPrice: ["₹550", ".00/month"],
            yPrice: ["₹500", ".00/month"],
            oldPrice: "",
            description: "Perfect for businesses with multi location stores to manage branches and daily operations.",
            features: ["Unlimited customers", "Unlimited orders", "Generate reports", "Create multiple branches", "Lifetime Updates"],
            url: "https://google.com",
            featured: "",
            m_link: "http://localhost:3000?signup=true&billing_cycle=month&plan=Enterprise",
            y_link: "http://localhost:3000?signup=true&billing_cycle=year&plan=Enterprise",
        }
    ];

    if (!plans) plans = defaultPlans;

    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    const [listAllPrice, setListAllPrice] = useState([]);

    useEffect(() => {
        getAllPlans()
    }, []);

    const getAllPlans = () => {
        const apiGetPriceRequest = {
            apiParameters: {"id": 621,"admin_id": 1,"shop_id": null,"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ0eXBlIjoic3VwZXJhZG1pbiIsImlhdCI6MTY3MzI2MDkyNSwiZXhwIjoxNzA0Nzk2OTI1fQ.xUVAM3kdy7nKAYCg_7G6SjqfK5AwKdlnIWKE7lT-jFc"}
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post('https://api.getmaap.com/api/superadmin/list/price/front', apiGetPriceRequest, { headers: headers }).then(res => {
            if (res.data.success) {
                setListAllPrice(res.data.data)
            }
        })
    }

    return (
        <Container id="pricing">
            <ContentWithPaddingXl>
                <HeaderContainer>
                    {subheading && <Subheading>{subheading}</Subheading>}
                    <Heading>{heading}</Heading>
                    {description && <Description>{description}</Description>}
                    <PlanDurationSwitcher>
                        {planDurations.map((planDuration, index) => (
                            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
                        ))}
                    </PlanDurationSwitcher>
                </HeaderContainer>
                <PlansContainer>
                    {/* {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                  <span className="slash"> / </span>
                  <span className="duration">{planDurations[activeDurationIndex].text}</span>
                </span>
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton>{primaryButtonText}</BuyNowButton>
              </PlanAction>
            </Plan>
          ))} */}
                    {listAllPrice.map((plan, index) => (
                        <>
                        <Plan key={index} featured={plan.featured}>
                            <PlanHeader>
                                <span className="nameAndFeaturedContainer">
                                    <span className="name">{plan.name}</span>
                                    {plan.featured && <span className="featuredText">{plan.featured}</span>}
                                </span>
                                <div className="pricingContainer">
                                    <span className="currentPrice">
                                        <span className="bigText">{activeDurationIndex === 0 ? plan.mPrice[0] : plan.yPrice[0]}</span>
                                        {activeDurationIndex === 0 ? plan.mPrice[1] : plan.yPrice[1]}{" "}
                                    </span>
                                    {plan.oldPrice && <span className="oldPrice">{plan.oldPrice}</span>}
                                </div>
                                <p className="description">{plan.description}</p>
                            </PlanHeader>
                            <PlanFeatures>
                                {plan.features.map((feature, index) => (
                                    feature ?
                                    <li className="feature" key={index}>
                                        <CheckboxIcon className="icon" />
                                        <span className="text">{feature}</span>
                                    </li> : ''
                                ))}
                            </PlanFeatures>
                            {/* 'http://localhost:3000?signup=true&price_id='+activeDurationIndex === 0 ? plan.mPriceId : plan.yPriceId+'&item_name='+plan.name+'&price='+activeDurationIndex === 0 ? plan.mPrice[0] : plan.yPrice[0]+'&plan_name='+activeDurationIndex === 0 ? 'Monthly' : 'Annual' */}
                            <PlanAction>
                                <ActionButton as="a" href={ activeDurationIndex === 0 ? plan.m_link : plan.y_link } target="_blank">
                                    {primaryButtonText}
                                </ActionButton>
                                <p tw="text-sm text-center mt-4">No card required, cancel any time</p>
                            </PlanAction>
                        </Plan>
                        </>
                    ))}
                </PlansContainer>
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
