import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink, SecondaryLink } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase, SecondaryButton as SecondaryButtonBase } from "components/misc/Buttons.js";
import { DefaultButton as DefaultButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"
import { Demo } from "components/forms/SimpleContactUs.js";
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";



const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-center items-center lg:pt-12 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-4xl text-center`;
const Heading = tw(SectionHeading)`text-center text-black leading-snug xl:text-5xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 text-center pb-8`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5 m-2`;
const DefaultButton = tw(SecondaryButtonBase)` mt-8 inline-block w-56 tracking-wide text-center py-[1.12rem] m-2`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
// const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
// const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
// const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
// const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`
// const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
// `;
// const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`
// const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
// const Quote = tw.blockquote``
// const CustomerName = tw.p`mt-4 font-bold`
// const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`


export default ({
    heading = "Maap is India’s 1st cloud platform empowering tailors with order and measurement management.",
    description = "If world of fashion changes, so should your tailoring process. Maap is cloud based software crafted to digitalize whole tailoring process to deliver exceptional customer experiences in an easy and efficient way by freeing you from extra load of administrative work.",
    // imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
    // imageDecoratorBlob = true,
    primaryButtonUrl = "https://www.getmaap.com/app/",
    primaryButtonText = "Get Started",

    defaultButtonUrl = "/demo",
    defaultButtonText = "Book a Demo",
    buttonRounded = true,
    features = ["Available in 7 Locations", "Premium Internet Backbone", "99.99% Uptime SLA"],
    // testimonial = {
    //   quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   customerName: "Charlotte Hale",
    //   customerCompany: "Delos Inc."
    // }
}) => {

    const navigate = useNavigate()

    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const navLinks = [
        <NavLinks key={1}>
            <NavLink to={"/#features"} smooth >Features</NavLink>
            <NavLink to={"/#benefits"} smooth >Benefits</NavLink>
            <NavLink to={"/#pricing"} smooth>Pricing</NavLink>
            <NavLink to={"/#testimonials"} smooth>Testimonials</NavLink>
            <NavLink to={"/#about"} smooth>About</NavLink>
            {/* <NavLink to={"#contact-us"} >Contact Us</NavLink> */}
        </NavLinks>,
        <NavLinks key={2}>
            <NavLink href="https://www.getmaap.com/app/" tw="lg:ml-12!">
                Login

            </NavLink>



            <PrimaryLink onClick={() => window.location.href("https://www.getmaap.com/app/")} css={buttonRoundedCss} href="/https://getmaap.com/app">
                Sign Up
            </PrimaryLink>
            <SecondaryLink maryLink css={buttonRoundedCss} href="https://treact.owaiskhan.me/" tw="lg:ml-2!">
                Book a demo
            </SecondaryLink>
        </NavLinks >
    ];
    return (
        <>
            <Container>
                <ContentWithVerticalPadding>
                    <Row>
                        <TextColumn>
                            <Heading>{heading}</Heading>
                            <Description>{description}</Description>
                            <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
                                {primaryButtonText}
                            </PrimaryButton>
                            <DefaultButton as="a" href={defaultButtonUrl} css={buttonRoundedCss}>
                                {defaultButtonText}
                            </DefaultButton>
                            {/* <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList> */}
                        </TextColumn>
                        {/* <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <QuotesLeftIcon/>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn> */}
                    </Row>
                </ContentWithVerticalPadding>
            </Container>
        </>
    );
};




// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import CheckboxIcon from "feather-icons/dist/icons/check-circle.svg";
// import Header from "../headers/light";
// import DesktopNavLinks from "../headers/light";
// import NavLink from "../headers/light";
// import LogoLink from "../headers/light";
// import NavToggle from "../headers/light";
// import NavLinks from "../headers/light";
// import PrimaryLink from "../headers/light";


// class HeroSection extends Component {
//     render () {
//         const {
//             heading = "Maap is India’s 1st cloud platform empowering tailors with order and measurement management.",
//             description = "If world of fashion changes, so should your tailoring process. Maap is cloud based software crafted to digitalize whole tailoring process to deliver exceptional customer experiences in an easy and efficient way by freeing you from extra load of administrative work.",
//             primaryButtonUrl = "https://www.getmaap.com/app/",
//             primaryButtonText = "Get Started",
//             defaultButtonUrl = "/demo",
//             defaultButtonText = "Book a Demo",
//             buttonRounded = true,
//             features = ["Available in 7 Locations", "Premium Internet Backbone", "99.99% Uptime SLA"],
//         } = this.props;

//         const buttonRoundedCss = buttonRounded ? "rounded-full" : "";
//         const navLinks = (
//             <div className="nav-links">
//                 <Link to="/#features">Features</Link>
//                 <Link to="/#benefits">Benefits</Link>
//                 <Link to="/#pricing">Pricing</Link>
//                 <Link to="/#testimonials">Testimonials</Link>
//                 <Link to="/#about">About</Link>
//             </div>
//         );

//         return (
//             <div className="container">
//                 <div className="content-with-vertical-padding">
//                     <div className="row">
//                         <div className="text-column">
//                             <h2 className="text-center text-black leading-snug xl:text-5xl">{heading}</h2>
//                             <p className="mt-4 lg:text-base text-gray-700 text-center pb-8">{description}</p>
//                             <a href={primaryButtonUrl} className={`primary-button ${buttonRoundedCss}`}>
//                                 {primaryButtonText}
//                             </a>
//                             <a href={defaultButtonUrl} className={`default-button ${buttonRoundedCss}`}>
//                                 {defaultButtonText}
//                             </a>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default HeroSection;
