import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
// import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
// import SimpleWithSideImage from "./faqs/SimpleWithSideImage";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full flex justify-between items-center max-w-2xl bg-white drop-shadow-[0_0_1.5rem_rgba(162, 115, 255,0.4)] rounded-full py-2 px-2 mt-8`;
const SwitchButton = styled.button`
  ${tw`px-2 py-4 basis-[33.3334%] rounded-full focus:outline-none text-[12px] xs:text-sm sm:text-lg font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

// const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
// const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
// const Plan = styled.div`
//   ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
// `;

// const PlanHeader = styled.div`
//   .nameAndFeaturedContainer {
//     ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
//   }
//   .name {
//     ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3`}
//   }
//   .featuredText {
//     ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
//   }
//   .pricingContainer {
//     ${tw`mt-6 flex items-end justify-between`}
//     .currentPrice {
//       ${tw`text-lg font-bold leading-none`}
//       .bigText {
//         ${tw`text-3xl font-bold`}
//       }
//     }
//     .oldPrice {
//       ${tw`text-gray-500 text-lg line-through hidden sm:block`}
//     }
//   }
//   .description {
//     ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
//   }
// `;
// const PlanFeatures = styled.ul`
//   ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
//   .feature {
//     ${tw`flex items-start mt-6 first:mt-0`}
//     .icon {
//       ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
//     }
//     .text {
//       ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
//     }
//   }
// `;

// const PlanAction = tw.div`px-4 pb-8`;
// const ActionButton = styled(PrimaryButtonBase)`
//   ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
// `;
// const BuyNowButton = styled(PrimaryButtonBase)`
//   ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
// `;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Any Questions ?",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plans = null,
  primaryButtonText = "Try it Free",
  planDurations = [
    {
      text: "Subscription",
      switcherText: "Subscription",
    },
    {
      text: "Platform",
      switcherText: "Platform",
    },
    {
      text: "Features",
      switcherText: "Features",
    }
  ],
  setActiveQuestionIndex
}) => {

  const defaultPlans = [
    {
      name: "Free",
      durationPrices: ["₹0", "₹0"],
      mPrice: ["₹0", ".00/month"],
      yPrice: ["₹0", ".00/month"],
      oldPrice: "",
      description: "Perfect for when you want to host your personal blog or a hobby side project.",
      features: ["Unlimited customers", "Upto 100 orders"],
      url: "https://google.com"
    },
    {
      name: "Individual",
      mPrice: ["₹450", ".00/month"],
      yPrice: ["₹400", ".00/month"],
      oldPrice: "",
      description: "Perfect for hosting blogs with lots of traffic or heavy development projects",
      features: ["Unlimited customers", "Unlimited orders", "Generate reports"],
      url: "https://google.com",
      featured: ""
    },
    {
      name: "Enterprise",
      mPrice: ["₹550", ".00/month"],
      yPrice: ["₹500", ".00/month"],
      oldPrice: "",
      description: "Perfect for hosting production websites & API services with high traffic load",
      features: ["Unlimited customers", "Unlimited orders", "Generate reports", "Create multiple branches", "Lifetime Updates"],
      url: "https://google.com"
    }
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
              <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => { setActiveDurationIndex(index); setActiveQuestionIndex(index) }}>{planDuration.switcherText}</SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container >
  );
};
