import React, { useState } from "react";
// import { motion } from "framer-motion";
import tw from "twin.macro";
// import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
// import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import TwoColumnPrimaryBackground from "./TwoColumnPrimaryBackground";
import Tabs from "../CustomTabs"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div`w-full`;

const HighlightedText = tw.span`text-primary-500`

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
//   props.imageShadow ? tw`shadow` : tw`shadow-none`,
//   tw`hidden lg:block rounded h-144 bg-center`
// ]);

const FAQContent = tw.div`flex justify-center items-center`;
// const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-center`;
// const Heading = tw(SectionHeading)`text-center`;
// const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.div`border rounded-lg p-4 md:p-8 max-w-4xl w-full`;
const FAQ = tw.div` first:mt-0 mt-8 border-0 px-4 py-4 lg:p-0 rounded-lg lg:rounded-none overflow-hidden`;
// const Question = tw.div`flex justify-between items-center`;
// const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
// const QuestionToggleIcon = styled.span`
//   ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
//   svg {
//     ${tw`w-4 h-4`}
//   }
// `;
// const Answer = motion(tw.div`text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Here are some frequently asked questions about our hotels from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Subscription and Pricing",
      answer: [
        {
          question: "Can I get a trial period before purchasing the subscription plan?",
          answer:
            "Yes, We offer a 90-day trial period for our platform. When your trial expires, you can continue using the product by adding a debit/credit card inside the “Subscription” section. If you decide to discontinue or feel like it’s not made for you, after the first paid month/year, you can choose not to add a credit/debit card at the end of your 90-day standard trial, your subscription will be downgraded to the “Free” plan."
        },
        {
          question: "Will my data be lost while downgrading the subscription plan?",
          answer:
            "No, while upgrading or downgrading the subscription plan on the Maap SaaS platform, the customer’s data will not be lost and will persist until the customer intentionally opt-ins to delete the account from the platform. "
        },
        {
          question: "What happens if I cancel my subscription?",
          answer:
            "If you cancel your subscription, you can use the platform until the end of the current subscription period and after that, your subscription will be downgraded to the “Free” plan and you will not be able to use the paid features of your previous subscription plan."
        },
        {
          question: "Is there a demo version I can try?",
          answer:
            "Yes, you can select the “Free” plan, or to try paid features you can set up your free trial for 90 days of any premium plan."
        },
        {
          question: "Is it safe to use my credit/debit card on the Maap platform?",
          answer:
            "Yes, you can select the “Free” plan, or to try paid features you can set up your free trial for 90 days of any premium plan."
        }
      ]
    },
    {
      question: "Maap Platform",
      answer: [
        {
          question: "Can I Track My Order Status?",
          answer:
            "Yes, you can track all orders from the “Orders” section in the platform with different types of statuses such as “Order received”, “Assigned to the worker”, “In production”, etc. assigned to a particular category (“Kurta”, “Blazer”, etc.) of the entire order."
        },
        {
          question: "Will the customer’s measurements be shared anywhere outside the platform?",
          answer:
            "By default Maap platform only shares measurement details with the assigned worker, but it is configurable inside the platform to share the measurement slip with the customer also."
        },
        {
          question: "Can I customize the Maap platform according to my business needs?",
          answer:
            "Yes, you can freely customize and add new categories with different styles, also you will be able to choose multiple languages between English and Gujarati."
        }
      ]
    },
    {
      question: "Features",
      answer: [
        {
          question: "What are the basic advantages/services provided by the Maap platform?",
          answer: [
            "Calculated data of total earnings and order counts of each category shown in form of graphs on the dashboard with filters (“Monthly”, “Quarterly” and “Yearly”).",
            "Frequent upcoming orders will be displayed on the dashboard for easy and faster accessibility.",
            "Customer details with measurements are stored, for easy and fast accessibility.",
            "Easy to find orders with particular filtering from the list of all orders.",
            "Features included in paid plans are automatically sending messages on SMS and Whatsapp."
          ]
        },
        {
          question: "Can I edit measurements if they don't look correct?",
          answer:
            "Yes. Our platform allows you to edit measurements for each particular order and category-wise."
        },
        {
          question: "How will my customer get benefits if we use the Maap platform?",
          answer: [
            "Customers are provided with an automated invoice in PDF format at the end of the ordering process on their registered Whatsapp phone number.",
            "Customers will receive an automated SMS on their registered phone number when the order will be ready to be delivered.",
            "Customers can directly pay the bill from the UPI QR code provided in the invoice PDF."
          ]
        }
      ]
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column>
            <Tabs subheading="FAQs" setActiveQuestionIndex={setActiveQuestionIndex} heading={<> Any <HighlightedText> Questions ?</HighlightedText></>} color="pink" />
            <FAQContent>
              <FAQSContainer>
                <FAQ
                  key={activeQuestionIndex}
                  className="group"
                >
                  <TwoColumnPrimaryBackground faqs={defaultFaqs[activeQuestionIndex].answer}></TwoColumnPrimaryBackground>
                </FAQ>
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};

