import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Image1 from 'images/4644714.png'
import Image2 from 'images/2133952.png'
import Image3 from 'images/4515603.png'
import Image4 from 'images/8572894.png'


import * as Header from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";
// import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import Tabs from "components/Tabs/Main.js"
import HorizontalTabs from "components/horizontalTabs/Main.js"
import OurStory from "components/testimonials/SimplePrimaryBackground.js";

import FaqSection from "components/custom/FaqSection";
import HeroSection from "components/custom/HeroSection";

const HighlightedText = tw.span`text-primary-500`

export default () => {
    return (
        <AnimationRevealPage>
            <Header.default />
            <HeroSection />
            {/* <div className="people-img" style={{ justifyContent: 'center', display: 'flex', position: 'relative' }}>
                <ul style={{ display: 'flex', textAlign: 'center', position: 'relative', paddingTop: '0', }}>
                    <li style={{ display: 'inlineBlock', width: '25%', position: 'relative', right: '-100px', top: '0', marginTop: "-40px" }}>
                        <img src={Image1} fetchPriority="high" width="320" height="411" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                    <li style={{ display: 'inlineBlock', position: 'relative', width: '18%', right: '-38px', top: '28%' }}>
                        <img src={Image2} fetchPriority="high" width="320" height="329" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                    <li style={{ display: 'inlineBlock', position: 'relative', width: '15%', left: '30px', top: '38%' }}>
                        <img src={Image4} fetchPriority="high" width="243" height="335" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                    <li style={{ display: 'inlineBlock', position: 'relative', width: '25%', left: '45px', top: '0%' }}>
                        <img src={Image3} fetchPriority="high" width="396" height="423" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                </ul>
            </div> */}
            <div className="people-img" style={{ justifyContent: 'center', display: 'flex', alignItems: "center" }} tw={'xl:hidden py-8'}>
                <ul style={{ display: 'flex', position: 'relative', paddingTop: '0', }} tw={'justify-center items-end max-w-4xl'}>
                    <li style={{ display: 'inlineBlock' }} tw={ 'basis-[25%]' }>
                        <img src={Image1} fetchPriority="high" alt="Zpeople" tw={'animate-spin-slow duration-1000 object-contain'}></img>
                    </li>
                    <li style={{ display: 'flex' }} tw={ 'basis-[22%] justify-center items-start' }>
                        <img src={Image2} fetchPriority="high" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                    <li style={{ display: 'inlineBlock' }} tw={ 'basis-[22%]' }>
                        <img src={Image4} fetchPriority="high" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                    <li style={{ display: 'inlineBlock' }} tw={ 'basis-[38%]' }>
                        <img src={Image3} fetchPriority="high" alt="Zpeople" tw={'animate-spin-slow duration-1000'}></img>
                    </li>
                </ul>
            </div>
            {/* <MainFeature
        heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
      /> */}
            <Tabs />
            <HorizontalTabs heading={<>Amazing <HighlightedText>Features</HighlightedText></>} />
            <Features
                heading={<>Amazing <HighlightedText>Benefits</HighlightedText></>
                }
            />
            <Pricing
                heading={<>Individual or Enterprise? <HighlightedText>We’ve got you covered.</HighlightedText></>}
            />
            <FaqSection />
            <Testimonial
                heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
            />
            <OurStory
                heading={<>Our maap story</>}
            />
            <GetStarted />
            <Footer />
        </AnimationRevealPage>
    );
}
